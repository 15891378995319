/* html, #root, .dk_content {
	height: 100%;
} */
body {
    font-family: Helvetica, sans-serif;
    margin: 0;
}
.dk_body {
    background: url(https://images.unsplash.com/photo-1644707386494-5160a7452518?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1vZi10aGUtZGF5fHx8fGVufDB8fHx8&auto=format%2Ccompress&fit=crop&w=2400&h=2400) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.dk_body {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}
.dkHeader_section { padding-top: 10px;}.dk_clear { overflow: hidden;clear: both; }
body, html { overflow: auto; }
blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre { margin: 0; } .flex_col {flex-direction: column;}
.hide { display: none !important; }.error { border-color: #F82A4B !important; }.items-center { align-items: center; }.flex { display: flex; }
.dkSearch_section { width: 100%;margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.dk_content_area { min-height: 100vh; overflow: hidden; max-width: 97%; margin: 0px auto; padding: 0px 16px; width: 100%; flex-direction: column; position: relative; justify-content: space-between; }
.dk_dkSearch_menu ul { background: #fff; color: #232323; border: 1px solid rgba(35,35,35,.2); padding: 8px 15px; border-radius: 30px;max-width: max-content; }
.dk_dkSearch_menu ul li { color: rgba(35,35,35,.5); margin-left: 10px; margin-right: 10px; cursor: pointer; list-style: none; font-size: 20px; line-height: 28px; padding: 2px 6px; }
.search_option.active{ color: #40bb6a; border-bottom: 2px solid #40bb6a; padding-bottom: 0; }
.dk_container { max-width: 720px; width: 96%;} .search_option:hover {border-bottom: 2px solid #40bb6a; padding-bottom: 0; }
.dk_dkSearch_form { display: grid; align-items: center; grid-template-columns: 1fr 60px;box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);border-radius: 30px; }
.dk_dkSearch_form input { background: #fcfcfc; border: transparent; font-size: 18px; line-height: 24px; min-height: 48px; border-top-left-radius: 30px; border-bottom-left-radius: 30px; padding: 0px 20px;outline: 0;max-width: unset; }
.dk_dkSearch_form button { cursor: pointer; height: 48px; color: #40bb6a; border: none; background: #fff; border-top-right-radius: 30px; border-bottom-right-radius: 30px; font-size: 20px; }
.dk_shortcut { display: grid; grid-template-columns: repeat(5,1fr); justify-items: center; margin-top: 20px; }.addShortcut { font-size: 28px; } 
.single_shortcut_area, .addShortcut { height: auto; display: flex; justify-content: center; align-items: center; border-radius: 100%; transition: .3s;border: 1px solid rgba(35,35,35,.2); width: 58px; height: 58px; background: #fff; cursor: pointer; }
.shortcut-title { position: absolute; background: #000; border-radius: 5px; padding: 3px 6px 1px; max-width: 150px; overflow: hidden; text-align: left; top: -30px; z-index: 2; visibility: hidden;color: #fff; }
.shortcut-title pre { font-family: Helvetica, sans-serif; line-height: 20px; font-size: 14px; }.mainDiv { position: relative;}
.shortcut-close i { font-size: 20px; background: #fff; border-radius: 50%; }.dkFooter_section_area { padding: 10px 50px 10px 0;align-items: end; }
.shortcut_img, .shortcut_img img { width: 32px; height: 32px;}.single_shortcut_area:hover, .addShortcut:hover { background: #e6e6e6; }
.single_shortcut { width: 100%; justify-content: space-around; align-items: center; margin-bottom: 10px;cursor: pointer; }
.shortcut-down-arrow { position: absolute; content: ""; border: solid #000; border-width: 0 3px 3px 0; padding: 3px; top: -15px; left: 42%; transform: rotate(45deg); -webkit-transform: rotate(45deg); z-index: 3; visibility: hidden; }
.shortcut-close { position: absolute; top: -5px; right: -20px; border-radius: 50000%; visibility: hidden; transition: .2s; cursor: pointer; }
.single_shortcut:hover .shortcut-close, .single_shortcut:hover .shortcut-down-arrow, .single_shortcut:hover .shortcut-title { visibility: visible; }
.dkModal { z-index: 20001; overflow-y: auto;position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); scrollbar-width: none; }
.dkModal_area { max-width: 540px; transform: translate(0,-50px); transition: transform .3s ease-out; margin: 0px auto; background-color: #fdfcfa; background-clip: padding-box; border: unset; border-radius: 5px !important; outline: 0; padding: 20px; }
.dkModal.show .dkModal_area { transform: translate(0, 60%) !important; }.dkModal_area h2 { font-size: 24px;margin-bottom: 20px; }
.dkFiled label { color: rgb(35, 35, 35); display: block; margin-bottom: 5px; line-height: 24px; }
.dk_input { border: 1px solid #16222a; width: 96%; padding: 4px; border-radius: 8px; margin-bottom: 20px; line-height: 24px; font-size: 16px; }
.dkModal_footer { justify-content: end; margin-right: 2%; }.dkModal_footer button:nth-child(1) { margin-right: 8px; }
.dkModal_footer button { padding: 4px 6px; background: transparent; border: 1px solid #16222a; border-radius: 4px; cursor: pointer; }
.footer_right_area { justify-content: end; align-items: center; }
.footer_left .dk_bigBTN { color: #fff; background: linear-gradient(90deg,#40BB6A,#13381f,#2c824a);border-radius: 1.5em; z-index: 999; padding: 12px 24px; font-family: Helvetica,sans-serif; font-size: 16px; font-weight: 600; line-height: 1.5; border: transparent; outline: transparent; cursor: pointer; animation: glow 16s linear infinite; }
.dk_bigBTN:hover { box-shadow: 0px 0px 15px 3px #2c824a; }
.footer_right { flex: 1; position: fixed;bottom: 20px;right: 30px;z-index: 1;}
.tooltip .tooltiptext { visibility: hidden; background-color: #000; color: #fff; text-align: center; padding: 2px 6px; position: absolute; top: -40px; right: -10px; z-index: 1; border-radius: 5px; }
.tooltip:hover .tooltiptext { visibility: visible; font-size: 14px; }.tooltip { position: relative; display: inline-block;height: max-content; }
.toolIcon { font-size: 20px; color: #2c824a; cursor: pointer; }.settings-icon-div span i { font-size: 10px; color: #2c824a; }
.footer_left { align-items: end; position: fixed; right: 15px; z-index: 1; bottom: 120px; }
.settings-icon-div span i:nth-child(2) { margin-left: 3px; } .settings-icon-div span:nth-child(1) { margin-bottom: 3px; }
.settings-icon-div { position: relative;z-index: 2; padding: 8.5px; text-align: center; background: #fff; border-radius: 8px;  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06); cursor: pointer; }
.settings-icon-div:hover { box-shadow: 0px 0px 15px 3px #fff; }.menu_popUp { position: absolute; overflow: visible; bottom: 50px; right: -20px;z-index: 1; }
.menu_popUp_area { display: flex; align-items: center; background-color: rgba(35,35,35,.4); border: 2px solid #fff; padding: 8px; border-radius: 8px; }
.single_menu { margin: 0px 5px; width: 27px; height: auto; } .single_menu i { color: #fff; font-size: 22px; }.single_menu svg path { fill: #fff; }
.single_menu .toolIcon { height: 25px; } .single_menu .tooltip .tooltiptext { padding: 4px 6px 2px;width: max-content; }
.tooltip_arrow { position: absolute; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid white; bottom: -8px; right: 32px; opacity: 1; }
.reminder_tool { width: 30px; height: 30px; justify-content: center; background: #fff; display: flex; align-items: center; border-radius: 5px; cursor: pointer;z-index: 1; }
/* weather */
.dk_weather { min-height: fit-content; z-index: 1; width: 203px; }
.dk_weather_area { background: #fff; color: #232323; border: 1px solid rgba(35,35,35,.2); display: block; overflow: hidden; clear: both; border-radius: 10px; margin-bottom: 15px; }
.weather_head { padding: 8px 8px 3px; } .weather-part { text-align: center; }.location_BTN .tooltiptext { top: -5px; left: 25px; width: 80px; }
.weather_form, .youtube_submit { flex: 1; justify-content: start; display: flex; margin-left: 5px; }
.weather_form input, .youtube_submit input { outline: 0; max-width: 123px; border: 1px solid #16222a; height: 34px; padding: 0 0 0 8px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; font-size: 15px; }
.weather_form button, .youtube_submit button { height: 36px; border: 1px solid #16222a; border-left: 0px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; cursor: pointer; color: #40bb6a; background: #fff; }
.dk_column .details .temp { justify-content: left; } .humidity .details span { margin-bottom: 4px !important; display: block; }
.wrapper .weather-part{ display: none; margin: 30px 0 0; align-items: center; justify-content: center; flex-direction: column; }
.wrapper.active .weather-part{ display: flex; }.weather-part img{ max-width: 50px; }.location i{ font-size:17px; margin:0 3px 0 0; }
.weather-part .temp { display: flex; font-weight: 500; font-size: 16px; justify-content: center;margin-bottom: 4px; }
.weather-part .temp .numb{ font-weight: 600; } .weather-part .weather{ margin-left: 4px; }
.weather-part .location { display: flex; font-size: 16px; text-align: center; align-items: flex-start; justify-content: center; }
.weather-part .bottom-details{ display: flex; width: 100%; justify-content: space-between; border-top: 1px solid #ccc; }
.bottom-details .dk_column{ display: flex; width: 100%; padding: 8px 0; align-items: center; justify-content: center; }
.dk_column i{ color: #5DBBFF; font-size: 25px; } .dk_column.humidity{ border-left: 1px solid #ccc; }
.dk_column .details{ margin-left: 3px;text-align: left; } .details .temp .deg{ margin: 0; font-size: 17px; padding: 0 2px 0 1px; }
.dk_column .details p{ font-size: 14px; margin-top: -6px; }.dk_CF_BTN { color: #40BB6A; padding: 2px 3px; cursor: pointer; }
.dk_CF { margin-left: 7px; color: #5DBBFF; } .location_BTN.active svg path { fill: #40BB6A; } .single_menu.active svg path { fill: #2c824a; }
.dk_mini_calendar { background: #fff; color: #232323; border: 1px solid rgba(35,35,35,.2); box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06); width: 203px; margin-bottom: 15px; border-radius: 8px; }
.dk_mini_calendar_area { padding: 15px;cursor: pointer; } .dk_mini_calendar h3 { font-size: 20px; font-weight: 400; } .mini_date { margin-top: 5px; }
/* Calendar */
.calendar .weeks li{ font-weight: 500; cursor: default; }.dkModal.show.cal_wrapper .dkModal_area { transform: translate(0, 10%) !important; }
.dkModal.cal_wrapper .dkModal_area {width: 100%; max-width: 960px;} .cal_footer{align-items: center;}
.cal_footer.icons span { height: 30px; width: 30px; cursor: pointer; text-align: center; line-height: 30px; font-size: 1.9rem; -webkit-user-select: none; user-select: none; border-radius: 50%; }
.icons span:last-child{ margin-right: -10px; } .cal_footer.icons span:hover{ background: #f2f2f2; } .cal_header .current-date { font-size: 18px; font-weight: 500;margin: 0 5px;}
.calendar ul{ display: flex; flex-wrap: wrap; list-style: none; text-align: center;padding-left: 0; } .calendar .days{ margin-bottom: 20px; }
.calendar li{ color: #B0968C; width: calc(100% / 7); font-size: 15px;margin-top: 10px; } .days li:not(.active):hover span{ background: #f2f2f2; }
.calendar .days li span { cursor: pointer; position: relative; z-index: 1; height: 40px; width: 40px; display: block; border-radius: 50%; text-align: center; margin: 0px auto; line-height: 40px; }
.days li.inactive{ color: rgba(34, 33, 91, 0.3); } .days li.active{ color: #fff; }.days li.active span { background: #B0968C;color: #fff; } 
.cal_header_right {align-items: center;}.cal_header_right .close i { font-size: 22px; width: 20px; text-align: center; padding: 1px 2px;cursor: pointer; }
.cal_header_right .close { flex: 1; text-align: right; }
.dashboard_contents .event_dot, .dashboard_contents .event_push { display: none !important; }
/* stickyNote */
.dk_note { height: 256px; width: 224px; color: #fff; border-radius: 8px;position: absolute;z-index: 1;background: #40bb6a; left: 15px;top:40px; }
.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#6BBE66;} .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FF4141;} .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#00A1F1;}
.dk_note_area { height: 232px; padding: 8px 16px 16px; } .note_head .tooltiptext { top: 0; left: 25px; width: max-content; padding: 5px 5px 3px 5px; }
.copy_note { flex: 1; text-align: right; }
.copy_note span { font-size: 30px; right: -5px; cursor: pointer; top: -3px; position: relative; line-height: 24px; }
.noteForm textarea { background: transparent; border: transparent; height: 200px !important; overflow: auto; width: 188px !important; font-size: 16px; line-height: 22px; color: #fff; outline: 0; resize: none; }
.colorPlate { position: fixed; overflow: visible; top: 17px; left: 80px; z-index: 2; }.colorList { border: 2px solid #fff; }
.colorList .sColor { width: 24px; height: 24px;cursor: pointer; } .default-color { background: #40bb6a; } .color-one { background: #e160ab; }
.color-two { background: #a064af; } .color-three { background: #5bc1ec; }.color-four { background: #7dceb7; } .color-five { background: #f8338c; } 
.color-six { background: #f43a32; } .color-seven { background: #fab632; }.sAction .toolIcon { padding: 0px 2px; margin-left: 3px; }
.arrow_left { position: absolute; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-right: 10px solid white; left: -10px; top: 3.5px; opacity: 1; }
.dk_sNote { z-index: 1; cursor: pointer; transform: rotate(0deg); width: 224px; height: 256px; max-width: 500px; max-height: 500px; min-height: 90px; min-width: 115px; left: 250px; top: 40px; position: absolute; border-radius: 8px; }
.sNote_action { flex: 1; justify-content: right; } .dk_sNote_area { padding: 10px; } .sAction.reminder .tooltip, .trash_note .tooltip { position: unset; }
.sAction svg, .sAction i { fill: #fff; color: #fff; font-size: 18px;width: 20px;height: 20px; } .dk_trashAction { justify-content: end; flex: 1; } 
.reminder .tooltip:hover .tooltiptext { margin-left: -23px; }.trash_note .tooltip:hover .tooltiptext { margin-left: 61px; max-width: 90px; top: 6px; }
.edit_note .tooltip:hover .tooltiptext { left: -65px; }.s_body p { font-size: 16px; line-height: 22px; color: #fff; outline: 0; resize: none; }
.dk_sNote_area .colorPlate { left: 8px; top: 40px; } .dk_sNote_area .colorPlate .arrow_left { display: none; }.text-center { text-align: center; }
.cls-1{fill-rule:evenodd;}.dk_sNote_area textarea { width: unset !important; }
.deleteNotes { position: absolute; z-index: 2; bottom: 50px; max-width: 200px; width: max-content; right: 0; }.reminder_area h6 { margin-bottom: 5px; font-size: 14px; }
.deleteNotes_area { background: #fff; color: #232323; border: 1px solid rgba(35,35,35,.2); padding: 8px; border-radius: 8px; margin-bottom: 5px; }
.allTrash_BTN pre { margin-bottom: 8px; font-size: 14px; color: rgb(220, 38, 38); font-family: Helvetica, sans-serif; cursor: pointer; display: inline-block; line-height: 20px; }
.sTrash { margin-top: 8px;align-items: center; } .sTrash span { font-size: 15px; line-height: 23px; }
.recicle i { color: #338fc9; } .put_trash i { color: red; } .dk_trashAction i { margin-left: 10px; cursor: pointer; }
.deleteNotes_area hr { color: transparent; border: .5px solid rgb(229, 231, 235); }
.dk_sNote .moveable-control.moveable-origin { display: none; } .dk_sNote .moveable-control-box { --moveable-color: none; }.subBTN { margin-top: 5px; }
.reminderMain { background: #fff; color: #232323; border: 1px solid rgba(35,35,35,.2); position: absolute; top: -15px; right: 90px; padding: 8px; border-radius: 5px; z-index: 2; }
.remove_time { border: transparent; background: transparent; flex: 1; margin-left: 5px; cursor: pointer; }

.dkModal.close { display: none; }


/* youtube */
.youtube-area-search { position: relative;z-index: 2; background: #fff; color: #232323; border: 1px solid rgba(35,35,35,.2); width: 300px; float: right; display: flex; padding: 12px 10px 12px 12px; border-radius: 8px; align-items: center; }
.youtube_submit input { max-width: unset; flex: 1; } .youtube_submit { margin-right: 10px;margin-left: 0; }
.video_right { position: absolute; z-index: 1; top: 10px; right: 15px; }
.youtube-area-search .close svg { width: 24px; height: 24px; cursor: pointer; fill: #000; margin-bottom: -2px; }
.youtube_submit button { width: 30px; font-size: 16px; }.youtube-area-items ul .sVideo img { width: 100px; height: 100px; margin-right: 8px; }
 .youtube-area-items ul .sVideo { margin-bottom: 12px;cursor: pointer; }.youtube-area-items ul { padding: 16px; margin: 0;}
.youtube-area-items ul .sVideo h5 { font-size: 16px; margin-bottom: 10px; cursor: pointer;} .youtube-area-items ul .sVideo p { line-height: 22px;cursor: pointer; }
.youtube-area-items ul .sVideo:last-child { margin-bottom: 0; } .youtube-area { flex: 1;display: flex; justify-content: end; }
.video_area { padding-top: 15px; border-radius: .5rem .5rem; box-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06); background: #fff; border: 1px solid rgba(35,35,35,.2); color: #232323;	height: 360px;	overflow: auto; }
.youtube-area-items { min-width: 250px; max-width: 520px; right: 360px; position: fixed; z-index: 1; padding-right: 12px; }
.right_arrow { position: absolute; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-left: 10px solid #c9c9c9; right: 2px; top: 31.875px; opacity: 1; }
.videoPlay_area { z-index: 1; position: relative; } .closeBTN { position: absolute; right: 35px; top: 6px; z-index: 2; width: 25px; height: 25px; cursor: pointer; }
.privacy-links ul a { color: #fff; font-size: 14px; line-height: 22px; }
.privacy-links ul { margin: 8px 0px; overflow: hidden; }
.privacy-links { position: fixed; left: 0px; top: 0; }
.privacy-links ul li { float: left; margin-right: 30px; list-style: none; }

.change-bg-lef {
    position: fixed;
    left: 30px;
    bottom: 20px;
    cursor: pointer;
    z-index: 99;
}
.root-container { position: relative; left: 0; right: 0; top: 0; bottom: 0; overflow-y: scroll; z-index: 1; }
.taboola-feeds {
    background-color: #242424;
    margin: 20px auto 0px;
    padding: 5px;
    box-sizing: border-box;
    height: 10000px;
    width: 98%;
    max-width: 1300px;
}
.change-bg-lef button.dk_bigBTN.tooltip {
    border: 0;
    background-color: #fff;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
    width: 40px; height: 40px;
}
.thumbs-feed-01-a .branding .logoDiv a span, .thumbs-feed-01-a .syndicatedItem .video-title, .branding .branding-inner { color: #fff; }

.scrollbar::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); border-radius: 7px; background-color: #F5F5F5; }

.scrollbar::-webkit-scrollbar { width: 8px; background-color: #F5F5F5; }

.scrollbar::-webkit-scrollbar-thumb { border-radius: 7px; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); background-color: #242424; }
.force-overflow { min-height: 450px; }
.taboola-feeds iframe{overflow: hidden; border: 0; width: 100%; height: 100%;}
